/**********************************
    GENERAL 
**********************************/

:root {
  --bs-blue: #196dd9 !important;
  --bs-primary: #196dd9 !important;
}

body {
  font-family: "Sarabun", sans-serif !important;
  color: #6c757d;
}

main {
  background: #f4f7fb;
}

.btn-primary,
.btn-outline-primary,
.btn-secondary,
.btn-outline-secondary,
.btn-danger,
.btn-outline-danger,
.btn-success,
.btn-outline-success {
  border-radius: 15px;
}

.btn-primary {
  background: #196dd9 !important;
  border-color: #196dd9 !important;
}

.btn-primary:hover {
  background: #003087 !important;
  border-color: #003087 !important;
}

.btn-outline-primary,
.btn-outline-primary:hover {
  color: #196dd9 !important;
  border-color: #196dd9 !important;
  background-color: transparent !important;
}

.btn-success {
  background: #0fc54d !important;
  border-color: #0fc54d !important;
}

.btn-success:hover {
  background: #22964a !important;
  border-color: #22964a !important;
}

.btn-secondary {
  background: #49566e !important;
  border-color: #49566e !important;
}

.btn-secondary:hover {
  background: #343d4e !important;
  border-color: #343d4e !important;
}

.card {
  border-color: #c1c0c0 !important;
  background: #f9fcff !important;
  border-radius: 15px !important;
}

.card-icon {
  font-size: 38px;
  color: #196dd9;
}

.form-control-lg {
  font-size: 1.1em !important;
}

form textarea {
  border-radius: 15px !important;
}

form select,
form textarea,
form input {
  color: #6c757d !important;
}

/**********************************
    COLOR PALETTE 
**********************************/

.bg-fondo-suave {
  background-color: #f9fcff;
}

.bg-fondo-oscuro {
  background-color: #f4f7fb;
}

/**********************************
    ACCESS 
**********************************/
.welcome-col {
  background: #196dd9;
  border-radius: 0px 0px 200px 0px;
  min-height: 100vh;
}

.signup-col {
  background-image: url("../img/bg-register.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0px 0px 200px 0px;
}

.sign-in,
.sign-up {
  background: #f4f7fb;
  min-height: 100vh;
}

.sign-in .card {
  width: 60%;
}

.sign-up .card {
  width: 90%;
}

.welcome-title h1 {
  max-width: 420px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.8);
  font-size: 2.2em;
}

.isotipo-concurso {
  /*mix-blend-mode: luminosity;*/
  max-width: 340px;
}

.register-col {
  background: url("../img/bg-register.jpg") no-repeat center;
  background-size: cover;
  border-radius: 0px 0px 200px 0px;
  height: 100vh;
}

@media (max-width: 767px) {
  .isotipo-concurso {
    mix-blend-mode: normal;
    width: 80%;
  }

  .welcome-title h1 {
    font-size: 1.8em;
    color: #003087 !important;
    text-shadow: none !important;
    text-align: center;
  }

  .welcome-col {
    border-radius: 0 !important;
    background-color: transparent !important;
    padding: 0;
    min-height: auto !important;
  }

  .sign-in {
    min-height: auto !important;
  }

  .sign-in .card {
    width: 90%;
  }
}

/**********************************
    DASHBOARD
**********************************/

header nav {
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 70px 70px;
}

@media (max-width: 767px) {
  header nav {
    border-radius: 0 0 50px 50px;
  }
}

.box-company-name {
  background: #f4f7fb;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  text-align: center;
  max-width: 600px;
}

.box-company-name h3 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  color: #1754a2;
}

.notifications i {
  font-size: 40px;
  color: #196dd9;
}

.theme-light {
  background-color: #f4f7fb;
  color: #212529;
  padding-top: 180px;
  padding-bottom: 60px;
  min-height: 100vh;
}

.btn-outline-primary {
  --bs-btn-color: #196dd9 !important;
  --bs-btn-border-color: #196dd9 !important;
  --bs-btn-hover-bg: #196dd9 !important;
  --bs-btn-hover-border-color: #196dd9 !important;
  --bs-btn-active-bg: #196dd9 !important;
  --bs-btn-active-border-color: #196dd9 !important;
  --bs-btn-disabled-color: #196dd9 !important;
  --bs-btn-disabled-border-color: #196dd9 !important;
}

.btn-outline-primary:hover {
  background-color: #196dd9;
  border-color: #196dd9;
}

.table {
  color: #6c757d !important;
}

.table-light {
  --bs-table-bg: #f4f7fb !important;
}

.table tbody {
  border-left: 2px solid #196dd9;
}

.table thead tr {
  border-bottom: 1px solid #f8f9fa;
}

.table tbody tr {
  border-color: #eaebef !important;
}

.table .actions a {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 0px !important;
}

.nav-tabs .nav-link.active {
  background: transparent !important;
  border-color: #196dd9 !important;
  color: #196dd9 !important;
  border-bottom: 0px !important;
}

.nav-tabs .nav-link {
  background: #a3c7f9;
  color: #fff;
  margin: 0 2px;
}

.nav-tabs .nav-link:hover {
  background: #196dd9;
}

.bg-good-practice-tag {
  background: #a3c7f9;
  color: #196dd9;
}

.btn-transparent {
  color: #6c757d !important;
}

span.text-red {
  color: red;
}

.pagination .page-item a {
  background: transparent;
  color: #c1c0c0;
}

.pagination .page-link.active {
  background: #196dd9 !important;
  border-color: #196dd9 !important;
  color: #fff !important;
}

.search-form .btn {
  background: #196dd9;
  color: #fff;
  height: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
  font-size: 16px;
}

.modal .card {
  background-color: #f9fcff;
  border: 0 !important;
}

.modal .modal-content {
  border: 1px solid #ddd;
}

.offcanvas li.nav-item a {
  padding: 10px;
  color: #6c757d;
}

.offcanvas li.nav-item .active {
  background-color: #f4f7fb;
  border-radius: 4px;
  color: #196dd9;
  font-weight: 500;
}

@media (max-width: 991px) {
  .navbar-brand {
    max-width: 55%;
  }

  .box-company-name {
    margin: 15px 0;
    display: none;
  }

  .box-company-name h3 {
    font-size: 20px;
    line-height: 26px;
  }

  .user-avatar img {
    width: 60px;
  }
}

@media (max-width: 768px) {
  .theme-light {
    padding-top: 130px;
  }
}

/**********************************
    Participations STATUS
**********************************/

/*
Azul Intenso - #196DD9
Azul Opaco - #1754A2

Sandia Intenso -#EC5B6E
Sandia Opaco - #C55765

Verde Intenso - #0FC54D
Verde Opaco - #22964A
Verde Mutual Intenso - #9CCF03
Verde Mutual Opaco - #8FBF00

Violeta Intenso - #7D6FF0
Violeta Opaco - #635BA4

Jazmin Intenso - #A3C7F9
Jazmin Opaco - #ADC2DE

Fondo Oscuro - #F4F7FB
Fondo Suave - #F9FCFF
Fondo Blanco - #FFFFFF

Filete Global Bordes - #C1C0C0

Naranja - #F6973B

*/

.status-send {
  background-color: #7d6ff0 !important;
  color: #fff !important;
  text-align: center;
}

.status-draft {
  background-color: #a3c7f9 !important;
  color: #fff !important;
  text-align: center;
}

.status-rejected {
  background-color: #ec5b6e !important;
  color: #fff !important;
  text-align: center;
}

.status-approved {
  background-color: #0fc54d !important;
  color: #fff !important;
  text-align: center;
}

.status-featured {
  background-color: #f6973b !important;
  color: #fff !important;
  text-align: center;
}

.status-winner {
  background-color: #9ccf03 !important;
  color: #fff !important;
  text-align: center;
}

.btn-send {
  background-color: #7d6ff0 !important;
  border-color: #7d6ff0 !important;
  color: #fff !important;
  border-radius: 15px !important;
}

.react-link:hover {
  cursor: pointer;
}

.clickable {
  background: #dae9ff !important;
  color: #196dd9 !important;
}

.clickable:hover {
  background: #196dd9 !important;
  border-color: #196dd9 !important;
  color: #fff !important;
}

@media (max-width: 540px) {
  nav {
    border-radius: 0 !important;
  }
  .navbar-brand img {
    max-width: 230px;
  }
}
